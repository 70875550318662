.App {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .App {
    text-align: center;
    /*zoom: 0.7; */
  }

}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-me {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /*animation: myMove 1s ease-out 0s 1 normal; */
}

.fade-me-slow {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /*animation: myMove 1s ease-out 0s 1 normal; */
}

ul {
  /*list-style-type: none !important;*/
  color: #000;
}

.sh-gdpr-box {
  bottom: -248pt;
  position: absolute;
  font-size: 12pt;
  /* border: solid;
  background-color: #ccc7cd8c;
  */
  padding: 5pt 2pt;
  font-weight: 500;
}

.back-button {
  cursor: pointer;
  position: fixed;
  left: 2%;
  top: 2%;
  zoom: 0.15;
  -ms-zoom: 0.15;
  -webkit-zoom: 0.15;
  -moz-transform:  scale(0.15,0.15);
  -moz-transform-origin: left center;


  opacity: 0.7;
}

.sh-final-submit {
  position: absolute;
  width: 100%;
  bottom: -123pt;
}

.sh-final-submit-button {
  background-color: #f3fcebe3 !important;
  padding: 0pt 18pt !important;
  border-color: #000000 !important;
  /* min-height: 5rem; */
  font-weight: 500;
  font-size: 1.6rem;
  min-height: 7.0rem !important;
  border-width: 2.5pt !important;
  filter: drop-shadow(5px 5px 5px #00000055);
}

.sh-overview-title {
  padding-bottom: 1.5rem;
  font-weight: bold;

}

.sh-overview-tr:nth-child(odd) {
  background-color: rgb(128 42 42 / 15%);
}

.sh-overview-box {
  border: solid;
  border-width: 2px;
  border-color: black;
  padding: 1rem;
  background-color: rgb(255 255 255 / 70%);
  backdrop-filter: blur(9px);
}

/*a:visited {
  color: #000;

}*/

.sh-overview-item:visited {
  color: #000;

}

.sh-overview-link {

  color: #000;
}

.sh-overview-item:hover {
  color: #000;

}

.sh-overview-item {
  font-weight: bold;
  padding: 3pt 5px;
  color: #000;
}

.sh-overview-left {
  text-align: left
}

.sh-overview-right {
  text-align: right;
  max-width: 14rem;
  min-width: 13rem;
}


.sh-top {
  position: fixed;
  bottom: 1%;
  right: 1%;
  font-size: 1 rem;

}

.sh-header1 {
  padding-bottom: 1.2rem;
  font-size: 2.0em;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
.sh-invisible{
  display: none;

}
}
.sh-invisible{
  opacity: 0;

}

.sh-hide-me {
  display: none;
}

.sh-price-spacer {

  height: 10rem;
}

@media screen and (max-width: 1200px) {
  .sh-price-spacer {

    height: 7rem;
  }
}

.sh-continue-button {
  color:#000;
  font-size: 1.7rem;
  padding: 20pt;
  min-width: 40pt;
  min-height: 30pt;
  border: solid;
  border-color: #000;
  background-color: #ead7d7ba;
  transition: 1s;
  max-width: 7rem;
  position: relative;
  right: -37%;
  top: -14px;
}

.sh-continue-button:hover {
  background-color: #f2dbb68f;
}

.sh-price-total {
  text-decoration: underline;
  text-align: right;
  position: absolute;
  bottom: 13.1rem;
  right: 1rem;
  /* padding-bottom: 6pt; */
}

.sh-price-total-text {
  text-decoration: none;
  text-align: left;
  position: absolute;
  bottom: 13.1rem;
  left: 1rem;
  /*padding-bottom: 6pt; */
}

@media screen and (max-width: 1200px) {
  .sh-price-total {
    bottom: 8.1rem;
  }
}

@media screen and (max-width: 1200px) {
  .sh-price-total-text {
    bottom: 8.1rem;
  }
}



.sh-price-inkl {

  font-size: 1.10rem;
  font-weight: 500;
  padding: 0 7px;
}

.sh-price-box {
  font-size: 1.4rem;
  border: solid;
  border-width: 0.1em;
  min-width: 12em;
  position: absolute;
  border-color: #000000;
  background-color: #ead7d7ba;
  top: 100%;
  width: 100%;
  padding-top: 14pt;
  padding-bottom: 14pt;
}

@media screen and (min-width: 1200px) {
  .sh-price-box {
    border: solid;
    border-width: 0.1em;
    border-color: #000000;
    min-width: 23rem;
    max-width: 24rem;
    position: absolute;
    top: 3.1rem;
    left: 104%;
    bottom: unset;

  }
}

.sh-mid-center {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sh-header {
  display: flex;
  min-height: 40pt;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  padding-bottom: 8pt;
}

.sh-main {
  top: 2rem;
  border: none;
  border-width: 1px;
  position: relative;
  max-width: 35rem;
  min-width: 35rem;
}


.sh-infobox {
  color: black;
  text-decoration: none !important;
  top: 0em;
  border: solid;
  border-width: 2pt;
  padding: 1em;
  margin: 1em;
  min-height: 28pt;
  min-width: 200pt;
  max-width: 500pt;
  /*display: inline-flex;*/
  align-items: center;
  justify-content: center;
  background-color: #ead7d7ba;
  font-size: 18pt;
  position: relative;
}

.sh-clean-link {
  text-decoration: none;
}

.sh-disabled {
  opacity: 22% !important;
}

.sh-disabled2 {
  opacity: 15% !important;
}

.sh-main-container {
  font-size: 1.8rem;
  max-width: 550pt;
  border: none;
  /*border-color:#FF2c34; */
}

.sh-button-darker-bg {
  background-color: #8cd895d6 !important;
  ;
}

.sh-button-input {
  font-size: 1.4rem;
  color: black;
  border: solid;
  border-width: 2pt;
  border-color: #292727;
  background-color: #ead7d763;
  text-decoration: none;
  margin: 9pt;
  padding: 0 7pt;
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 50pt;
  min-width: 4em;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sh-button-input:hover {
  background-color: #f2dbb68f;
}


.sh-number {
  background-color: #bef7bb80;
  min-height: 34pt;
  max-width: 60pt;
  padding: 10pt;
  color: black;
  font-size: 20pt;
  border: solid;
  border-width: 2px;
}

.sh-text {
  background-color: #afd0ad80;
  min-height: 34pt;
  min-width: 100pt;
  padding: 10pt;
  color: black;
  font-size: 20pt;
}

/*.sh-button-checkbox {
  min-width: 22rem !important;
}*/



.sh-button-sel {
  background-color: #bba2a299 !important;
}

.sh-button {
  cursor: pointer;
  color: black;
  border: solid;
  border-width: 2pt;
  border-color: #292727;
  background-color: #edffec9e;
  text-decoration: none;
  margin: 9pt;
  padding: 0 7pt;
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 2.8em;
  min-width: 4em;

  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sh-button:hover {
  background-color: #92cf944f;

}

@media screen and (max-width: 1200px) {
  .sh-logo {
    zoom: 50%;
    top: 1rem;
    left: 2rem;
    -ms-zoom: 0.5;
  -webkit-zoom: 0.5;
  -moz-transform:  scale(0.5,0.5);
  -moz-transform-origin: center;
  }
}

.sh-no-border-bg
{
  border: none !important;
  background: none !important;
}

.sh-logo {
  padding-top: 1rem;
  padding-bottom: 2rem;
  zoom: 70%;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media screen and (max-width: 1200px), (orientation:portrait) {
  html {

    font-size: 0.7em;
  }
  body{
    background-size: 140vh;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1426px) and (orientation:landscape){
html{

  zoom: 0.78;
}


}

body {
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  background-image: url('./bg02.jpg');
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: black;
}

.App-header {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.divTable {
  display: table;
  width: 100%;
  min-width: 16rem;
}

.divTableRow {
  display: table-row;

}

.divTableHeading {
  background-color: #000;
  display: table-header-group;

}

.divTableCell,
.divTableHead,
.divTableCellValue {
  border: dashed;
  border-width: 2px;
  display: table-cell;
  padding: 3px 10px;
  border: none;
  text-align: left;
  font-size: 1.3rem;
}

.divTableCellValue {
  padding-right: 1.0rem;
  text-align: right;
  /*border: dashed;
  border-color: red; */
}

.divTableHeading {

  display: table-header-group;
  font-weight: bold;
}

.divTableFoot {

  display: table-footer-group;
  font-weight: bold;
}

.divTableBody {
  display: table-row-group;
  border: none;
  font-size: 1em;
}
